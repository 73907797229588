import { Component, Input, OnInit } from '@angular/core';
import { SafeValue } from '@angular/platform-browser';

@Component({
	selector: 'app-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

	/** Pass a single message, or an array of tuples [delay, message], where delay is in milliseconds after the previous message. */
	@Input() message?: SafeValue | Array<[number, SafeValue]>;
	messageTimeouts: any[] = [];
	@Input() messageHeading?: SafeValue;
	currentMessage: SafeValue = '';
	constructor() { }

	ngOnInit(): void {
		if (this.message) {
			this.setMessage(this.message);
		}
	}

	setMessage(newMessage: SafeValue | Array<[number, SafeValue]>) {
		this.messageTimeouts.forEach((timeout) => {
			clearTimeout(timeout);
		});
		this.messageTimeouts.length = 0;
		if (newMessage instanceof Array) {
			let delayAcc = 0;
			for (let i = 0; i < newMessage.length; i++) {
				const [delay, message] = newMessage[i];
				delayAcc += delay;
				const timeout = setTimeout(() => {
					this.currentMessage = message;
				}, delayAcc);
				this.messageTimeouts.push(timeout);
			}
		}
		else if (newMessage) {
			this.currentMessage = newMessage;
		}
	}

}
