import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'services/authentication.service';

@Component({
  selector: 'app-sign-out',
  standalone: false,
  templateUrl: './sign-out.component.html',
  styleUrl: './sign-out.component.scss'
})
export class SignOutComponent implements OnInit {
	loading = true;
	constructor(private authService: AuthenticationService) { }

	async ngOnInit(): Promise<void> {
		await this.authService.logoutRedirect();
	}
}
