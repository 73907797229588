import { environment } from "environment";

export enum IdentityRedirectType {
	Login,
	LoginSilent,
	PostLogout
};

export function getIdentityClientId(): string {
	const subdomain = window.location.host;
	return `${subdomain}`;
}

export function getIdentityRedirect(redirectType: IdentityRedirectType): string {
	let uri = `${environment.identity.uri.scheme}${getIdentityClientId()}`;
	if (redirectType === IdentityRedirectType.Login) {
		uri += environment.identity.uri.callbackRoute;
	}
	else if (redirectType === IdentityRedirectType.LoginSilent) {
		uri += environment.identity.uri.silentRoute;
	}
	else if (redirectType === IdentityRedirectType.PostLogout) {
		uri += environment.identity.uri.logoutCallbackRoute;
	}
	return uri;
}
