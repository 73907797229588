import { Injectable } from "@angular/core";
import { isArray } from "lodash";
import { AuthenticationService } from "services/authentication.service";
import { environment } from "environment";

@Injectable({
	providedIn: 'root'
})
export class HttpWithAuth {
	constructor(private authService: AuthenticationService) {
	}

	async fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
		if (!(url instanceof Request)) {
			url = new Request(url);
		}

		const bearerToken = this.authService.accessToken;
		if (bearerToken && url.url.startsWith(environment.webApiUrl)) {
			if (init === undefined) {
				init = {
					headers: {
						Authorization: bearerToken
					}
				};
			}
			else if (init.headers === undefined) {
				init.headers = {
					Authorization: bearerToken
				};
			}
			else if (isArray(init.headers)) {
				init.headers.push(['Authorization', bearerToken]);
			}
			else if (init.headers instanceof Headers) {
				init.headers.set('Authorization', bearerToken);
			}
			else {
				init.headers = Object.assign(init.headers, {
					Authorization: bearerToken
				});
			}
		}

		return await fetch(url, init);
	}
}
