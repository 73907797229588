import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { SafeValue } from '@angular/platform-browser';
import { SpinnerComponent } from '../spinner.component';

@Component({
	selector: 'app-spinner-with-overlay',
	templateUrl: './spinner-with-overlay.component.html',
	styleUrls: ['./spinner-with-overlay.component.scss']
})
export class SpinnerWithOverlayComponent implements AfterViewInit {

	@Input() showSpinner: boolean = false;
	@Input() message?: SafeValue | Array<[number, SafeValue]>;
	@Input() messageHeading?: SafeValue;
	@ViewChild(SpinnerComponent) spinner!: SpinnerComponent;
	constructor() { }

	setMessage(newMessage: SafeValue | Array<[number, SafeValue]>) {
		this.message = newMessage;
		if (this.spinner) {
			this.spinner.setMessage(newMessage);
		}
	}
	ngAfterViewInit() {
		if (this.message) {
			this.spinner.setMessage(this.message);
		}
	}
}
