<div class="container">
	<div class="row justify-content-center" *ngIf="!!currentMessage || !!messageHeading">
		<div class="col-12 text-center message-box py-2">
			<h2 *ngIf="messageHeading">{{messageHeading}}</h2>
			<p *ngIf="currentMessage">{{currentMessage}}</p>
		</div>
	</div>
    <div class="row">
        <div id="loader">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="loading"></div>
        </div>
    </div>
</div>
