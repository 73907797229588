import { Component, OnInit } from '@angular/core';
import { UserManager } from 'oidc-client';
import { environment } from 'environment';

@Component({
  selector: 'app-logout-callback',
  standalone: false,
  templateUrl: './logout-callback.component.html',
  styleUrl: './logout-callback.component.scss'
})
export class LogoutCallbackComponent implements OnInit {

	error = false;
	claims: {key: string; value: string}[] = [];
	loading = true;

	constructor() { }

	async ngOnInit(): Promise<void> {
		const userManagerSettings = {
			...environment.oidc,
			response_type: 'code' // we'll always be using PKCE with userManager, the resource owner flow doesn't use userManager
		};
		const userManager = new UserManager(userManagerSettings);
		await userManager.signoutCallback();

		window.parent.postMessage('signoutComplete', '*');

		// Uncomment this if you want to inspect the user claims
		// if (user !== null) {
		// 	this.claims = [];
		// 	for (const [key, value] of Object.entries(user.profile)) {
		// 		this.claims.push({ key, value });
		// 	}
		// }
	}

}
