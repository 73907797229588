import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserManager } from 'oidc-client';
import { environment } from 'environment';
import { AuthenticationService } from 'services/authentication.service';

@Component({
  selector: 'app-login-silent',
  standalone: false,
  templateUrl: './login-silent.component.html',
  styleUrl: './login-silent.component.scss'
})
export class LoginSilentComponent implements OnInit {

	error = false;
	claims: {key: string; value: string}[] = [];

	constructor(private route: ActivatedRoute, private authService: AuthenticationService) { }

	async ngOnInit(): Promise<void> {
		if (this.route.snapshot.fragment!.indexOf('error') >= 0) {
			this.error = true;
			return;
		}

		const userManagerSettings = {
			...environment.oidc,
			response_type: 'code' // we'll always be using PKCE with userManager, the resource owner flow doesn't use userManager
		};
		const userManager = new UserManager(userManagerSettings);
		await this.authService.refreshPopup(userManager.signinSilentCallback());

		// Uncomment this if you want to inspect the user claims
		// if (user !== null) {
		// 	this.claims = [];
		// 	for (const [key, value] of Object.entries(user.profile)) {
		// 		this.claims.push({ key, value });
		// 	}
		// }
	}

}
